<template>
  <div></div>
</template>

<script>
export default {
  name: 'BuyerAnalytics',
};
</script>

<style lang="scss" scoped></style>
